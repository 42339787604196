const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs'

Rails.start()

// var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


import '../../assets/javascripts/vendor/bootstrap.min.js'

import '../../assets/javascripts/vendor/jquery.blockUI'
import '../../assets/javascripts/vendor/jquery.icheck.min.js'
import 'jquery-mousewheel'

import '../../assets/javascripts/vendor/perfect-scrollbar.js'
import '../../assets/javascripts/vendor/less-1.5.0.min.js'
import '../../assets/javascripts/vendor/jquery.cookie.js'
import '@claviska/jquery-minicolors'


import '../../assets/javascripts/app/main.js'

import jBox from 'jbox'
global.jBox = jBox


import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

flatpickr.localize(Russian);

import '../../assets/javascripts/vendor/jquery-ui-1.13.2.custom.min.js'

$(document).ready(() => {
  // stocks
  $("#navigation_object_name").autocomplete({
    source: $('#navigation_object_name').data('autocomplete-source'),
    messages: {
      noResults: '',
      results: function() {}
    },
    select: function(event, ui) {
      $("#navigation_object_name").val(ui.item.label);
      $("#navigation_object_id").val(ui.item.value);
      return false;
    },
    search: function() {
      return $("#submit-btn").prop('disabled', true);
    },
    response: function(event, ui) {}
  });

  // banners
  $.get("/stocks?format=embedded", null, function(e) {
    $("#stock-modal-body").html(e);
    $(".select-stock-button").click(function(e) {
      var id, name;
      id = $(e.target).data("id");
      name = $(e.target).data("name");
      $("#stocks-modal-button").html(name);
      $("#bannerable_id").attr("value", id);
      $("#submit-btn").prop('disabled', false);
      $("#close-modal-button").click();
    });
  });

  $(".bannerable-selector-btn").click(callBack);

  $("#bannerable_name").autocomplete({
    source: $('#bannerable_name').data('autocomplete-source'),
    messages: {
      noResults: '',
      results: function() {}
    },
    select: function(event, ui) {
      $("#bannerable_name").val(ui.item.label);
      $("#bannerable_id").val(ui.item.value);
      $("#submit-btn").prop('disabled', false);
      return false;
    },
    search: function() {
      $("#submit-btn").prop('disabled', true);
    },
    response: function(event, ui) {}
  });
})

var callBack, hide_all, root, unclick;
